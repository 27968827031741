import React, { Component } from "react";
import Title from "../atoms/title"


class Loader extends Component {
    constructor(props){
      super(props);
      this.state = {
        title: this.props.title,
        classNames: this.props.classNames,
        onComplete: this.props.onComplete,
        loaderProgress: 10,
        isComplete: false,
        isLinearLoader: this.props.isLinear,
        isCircularLoader: this.props.isCircular
      }
      this.activateLoader = this.activateLoader.bind(this);
      this.updateParent = this.updateParent.bind(this);

      if(this.state.isLinearLoader) this.activateLoader();
    }

    activateLoader() {
        setTimeout(() => {
            this.setState({
                loaderProgress: 100
            });
        }, 100);
        setTimeout(() => {
            this.setState({
                isComplete: !this.state.isComplete
            });
            this.updateParent();
        }, 1200);
    }

    updateParent() {
        this.state.onComplete(this.state.isComplete);
    }

    render() {
        const createClassList = () => {
            let classList = 'cm-loader';
            if(this.state.classNames) classList = classList + ' ' + this.state.classNames;
            return classList;
        }
    
        const titleComponent = (this.state.title) ? (
            <div className='cm-loader__title'>
                <Title
                    type='h3'
                    title={this.state.title}
                    align='center'
                    color='dark'
                />
            </div>
        ) : null;
    
        const linearLoaderInlineStyle = {
            width: this.state.loaderProgress + '%',
        };

        const linearLoaderComponent = (this.state.isLinearLoader) ? (
            <div className='cm-loader__progress-bar'>
                <div className='cm-loader__progress-bar-fill' style={linearLoaderInlineStyle}></div>
            </div>
        ) : null;

        const circularLoaderComponent = (this.state.isCircularLoader) ? (
            <div className='cm-loader__circular'>
                <div className="cm-loader__rings"><div></div><div></div><div></div><div></div></div>
            </div>
        ) : null;

        return (
            <div className={createClassList()}>
                {titleComponent}
                {linearLoaderComponent}
                {circularLoaderComponent}
            </div>
        );
    }
}


export default Loader;

{/* <Loader 
    title="Lorem Ipsum"
    classNames="extra__class-name--goes-here"
/> */}